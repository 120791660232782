import { useMutation } from "@tanstack/vue-query";

interface Variables {
  nid: string;
}

interface Options {
  onSuccess?: () => void;
  onError?: (error: Error) => void;
}

export const useConfirmRegisterResendEmail = (options: Options = {}) => {
  const { $authApi } = useNuxtApp();

  return useMutation<unknown, Error, Variables>({
    mutationFn: (body: Variables) => {
      return $authApi.post("/resend/email", body);
    },
    onSuccess: options?.onSuccess,
    onError: options?.onError,
  });
};
